<template>
  <div>
    <div v-if="edit">
      <div
        v-if="CONTROL_EXPIRED_AT(item.expiredAt)"
        class="alert alert-warning"
      >
        {{ $t("expiredPromotion") }}
      </div>
      <div
        :class="`alert alert-${item.isActive === 1 ? 'success' : 'danger'}`"
        v-else
      >
        {{
          item.isActive === 1
            ? $t("promotionIstActive")
            : $t("promotionIsNotActive")
        }}
        <div class="text-right">
          <button
            @click="SEND_ACTIVE_TO_COUPON(item.isActive === 1 ? 0 : 1)"
            :class="`btn btn-pill btn-sm ${
              item.isActive === 0 ? 'donate' : 'btn-danger'
            }`"
          >
            {{ item.isActive === 1 ? $t("publish") : $t("unpublish") }}
          </button>
        </div>
      </div>
    </div>
    <form name="promotion" @submit="SEND_PROMOTION_CODE">
      <div class="form-group">
        <label for="code">{{ $t("promotionCode") }}</label>
        <input id="code" type="text" v-model="form.code" class="form-control" />
      </div>
      <div class="form-group">
        <label for="code">{{ $t("discount") }} (%)</label>
        <input
          id="code"
          type="text"
          max="100"
          min="0"
          v-model="form.discount"
          class="form-control"
        />
      </div>
      <label for="">{{ $t("validPromotionDate") }}</label>
      <date-picker
        :lang="{
          formatLocale: {
            firstDayOfWeek: 1,
          },
        }"
        class="w-100"
        format="DD.MM.YYYY"
        :disabled-date="notBeforeToday"
        v-model="form.expiredAt"
        type="date"
      ></date-picker>
      <div class="form-group mt-3">
        <label for="currency">{{ $t("validCurrency") }}</label>
        <select
          v-model="form.currency"
          class="form-control"
          name=""
          id="currency"
        >
          <option :value="item.id" v-for="(item, i) in prices" :key="i">
            {{ item.label }}
            {{ item.isPaypal === 0 ? `(${$t("paypalIsNotValid")})` : "" }}
          </option>
        </select>
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";
export default {
  components: { DatePicker },
  props: {
    item: {
      default: () => {},
    },
    edit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        code: "",
        discount: 0,
        currency: 1,
        expiredAt: new Date(),
      },
      prices: [
        { id: 1, type: "TRY", label: "TRY (₺)", isPaypal: 0 },
        { id: 2, type: "USD", label: "USD ($)", isPaypal: 1 },
        { id: 3, type: "USD", label: "EUR (€)", isPaypal: 1 },
      ],
    };
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    IF_EDIT_SET_FORM() {
      if (this.edit)
        this.form = {
          ...this.item,
          expiredAt: new Date(this.item.expiredAt),
        };
    },
    CONTROL_EXPIRED_AT(date) {
      return new Date(date).getTime() < new Date().getTime();
    },
    async SEND_ACTIVE_TO_COUPON(active) {
      const d = {
        isActive: active,
        code: this.item.code,
        id: this.item.id,
        expiredAt: this.item.expiredAt,
        currency: this.item.currency,
        discount: this.item.discount,
      };
      const response = await this.$store.dispatch("SEND_UPDATE_COUPON", d);
      if (response) this.$emit("close");
    },
    async SEND_PROMOTION_CODE_WHICH_IS_UPDATE() {
      const d = {
        isActive: this.item.isActive,
        code: this.form.code,
        id: this.item.id,
        expiredAt: this.form.expiredAt,
        currency: this.form.currency,
        discount: this.form.discount,
      };
      const response = await this.$store.dispatch("SEND_UPDATE_COUPON", d);
      if (response) this.$emit("close");
    },
    async SEND_PROMOTION_CODE_WHICH_IS_INSERT() {
      const d = {
        code: this.form.code,
        expiredAt: this.form.expiredAt,
        currency: this.form.currency,
        discount: this.form.discount,
      };
      const response = await this.$store.dispatch("SEND_NEW_COUPON", d);
      if (response) this.$emit("close");
    },
    SEND_PROMOTION_CODE() {
      if (this.edit) this.SEND_PROMOTION_CODE_WHICH_IS_UPDATE();
      else this.SEND_PROMOTION_CODE_WHICH_IS_INSERT();
    },
  },
  mounted() {
    this.IF_EDIT_SET_FORM();
  },
};
</script>

<style></style>
