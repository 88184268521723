<template>
  <div
    class="tab-pane fade show active"
    id="about_tab"
    role="tabpanel"
    aria-labelledby="about-tab"
  >
    <div class="about_tab_content mt-4">
      <div class="container">
        <h2>
          {{ $t("coupons") }}
        </h2>
        <h5 class="text-muted mt-2 mb-4">{{ $t("addCouponToCourse") }}</h5>
      </div>
      <PromotionCodes></PromotionCodes>
    </div>
  </div>
</template>

<script>
import PromotionCodes from "../PromotionCodes/index.vue";
export default { components: { PromotionCodes } };
</script>

<style></style>
